
import { defineComponent, PropType } from "vue-demi";
import { Subscriber } from "@/interfaces/subscriber/subscriber.dto";

export default defineComponent({
  name: 'ModalQuizAnswer',
  props: {
    data: {
      type: Object as PropType<Subscriber.Dto>,
      required: true,
    },
  },
})
