import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f12ce25c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quiz-answer flex-column" }
const _hoisted_2 = { class: "fz12" }
const _hoisted_3 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.questions, (question, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "answer flex-column",
        key: index
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(question.name), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": ($event: any) => ((question.answer) = $event),
          placeholder: "Нет ответа",
          disabled: ""
        }, null, 8, _hoisted_3), [
          [_vModelText, question.answer]
        ])
      ]))
    }), 128))
  ]))
}